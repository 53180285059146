import React from "react";
import { Image, Text, ColumnsExtended } from "./style";
import InternalLink from "../InternalLink";
import { Button } from "../../sdk/Button";
import { Container, Section, Columns, Column } from "../../sdk/Layout";
import { LazyLoadImage } from "react-lazy-load-image-component";

function CTAPrivateLabel({ config }) {
    if (config.length === 0) return null;

    return (
        <Container>
            <Section>
                <ColumnsExtended>
                    <Column>
                        <Image>
                            <LazyLoadImage
                                src={config[0].image.img}
                                alt={config[0].title}
                            />
                        </Image>
                    </Column>
                    <Column>
                        <Text>
                            <div className="inner">
                                <h2>{config[0].title}</h2>
                                <p>{config[0].content}</p>
                                {config[0].link ? (
                                    <InternalLink
                                        {...config[0].link}
                                        className="button-blue"
                                    >
                                        <Button primary>
                                            {config[0].link.title}
                                        </Button>
                                    </InternalLink>
                                ) : null}
                            </div>
                        </Text>
                    </Column>
                </ColumnsExtended>
            </Section>
        </Container>
    );
}

export default CTAPrivateLabel;
