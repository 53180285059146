import styled from 'styled-components';
import { Columns } from '../../sdk/Layout';

export const Image = styled.figure`
    margin: auto;
  img {
    width: 100%;
  }

  @media (max-width: 768px) {
    max-width: 400px;
  }
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  .inner {
    h2 {
        font-size: 40px;
        color: ${(props) => props.theme.primaryDarkMode};
    }
    p {
        margin: 20px 0 30px 0;
        font-size: 18px;
        line-height: 28px;
        color: ${(props) => props.theme.gray};
    }
  }

  @media (max-width: 768px) {
    text-align: center;
    .inner {
        h2 {
            font-size: 22px;
        }
        p {
            font-size: 16px;
            line-height: 22px;
        }
        .button-blue {
            display: block;
            display: flex;
            justify-content: center;
        }
    }
  }
`;

export const ColumnsExtended = styled(Columns)`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;